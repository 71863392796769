:root {
  --font-report-small: 7pt;
  --font-report-normal: 8pt;
  --font-report-medium: 10pt;
  --font-report-large: 11pt;
  --font-report-heading: 14pt;
}

/* Layout */
.report-print-wrapper {
  break-after: always;
  width: 210mm;
  /* .8 length is a workaround to prevent page to be larger than one page. */
  height: 296.8mm;
}

.report-print-container {
  background-color: #ffcb5a;
  width: 100%;
  height: 148mm;
  position: relative;
}

.report-poster {
  height: 148.8mm;
}

.report-print-id {
  position: absolute;
  left: 8mm;
  top: 5mm;
  font-size: var(--font-report-large);
  font-weight: 600;
  line-height: 1;
}

.report-print-edsy-logo {
  position: absolute;
  top: 5mm;
  right: 8mm;
  width: 18mm;
}

.report-print-inner-background {
  background-color: #fff6e3;
  position: absolute;
  top: 18mm;
  left: 8mm;
  right: 8mm;
  bottom: 8mm;
  padding: 14mm 8mm 8mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-print-title {
  font-size: var(--font-report-heading);
  line-height: 1.3;
  font-weight: 600;
  color: white;
  width: 60%;
  display: flex;
  justify-content: center;
  border-radius: 9999px;
  padding: 3mm;
  position: absolute;
  left: 50%;
  top: 18mm;
  transform: translate(-50%, -50%);
  z-index: 1;
  @apply bg-primary;
}

.report-print-overall-info {
  display: flex;
  justify-content: space-between;
  padding: 0 8mm;
  width: 100%;
  margin-bottom: 8mm;
}

.report-print-student-info {
  font-size: var(--font-report-large);
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4mm;
}
.report-print-mascot-container {
  width: 30mm;
  height: 30mm;
  background-color: #eef1fc;
  border-radius: 10mm;
  padding: 2mm;
}
.report-print-mascot {
  width: 100%;
  height: 100%;
}

/* .report-print-overall-result */
.report-print-overall-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5mm;
}

.report-print-overall-result .overall-result-title {
  font-size: var(--font-report-large);
  font-weight: 600;
  display: flex;
  gap: 1mm;
}

.report-print-overall-result .overall-result-container {
  display: flex;
  gap: 4mm;
}

.report-print-overall-result .overall-result-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2mm;
}

.report-print-overall-result .standard-logo {
  object-fit: contain;
}

.report-print-overall-result .cefr-logo {
  width: 20mm;
}

.report-print-overall-result .cambridge-logo {
  margin-top: 2mm;
  width: 35mm;
}

.report-print-overall-result .label {
  width: 20mm;
  height: fit-content;
  color: white;
  background-color: #1f45b1;
  border-radius: 9999px;
  padding: 2mm 4mm;
  display: flex;
  justify-content: center;
  font-size: var(--font-report-medium);
  line-height: 1;
  margin-top: auto;
  @apply font-semibold;
}

/* .report-print-detail-score */
.report-print-detail-score {
  width: 100%;
  padding: 0 10mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-print-detail-score .title {
  font-size: var(--font-report-medium);
  font-weight: 600;
  margin-bottom: 1.5mm;
  @apply text-primary;
}
.report-print-detail-score .score-table {
  width: 100%;
  display: flex;
  gap: 0.5mm;
}

.report-print-detail-score .score-table > .box-item {
  flex-basis: 0;
  flex-grow: 1;
}

.report-print-detail-score .score-table > .box-item > .box-item-title {
  font-size: var(--font-report-normal);
  font-weight: 600;
  text-align: center;
  background-color: #ffcb5a;
  margin-bottom: 0.5mm;
  padding: 1mm;
}

.report-print-detail-score .score-table > .box-item:nth-child(1) > .box-item-title {
  background-color: #79c9cb;
}

.report-print-detail-score .score-table > .box-item > .box-item-result {
  font-size: var(--font-report-large);
  font-weight: 700;
  text-align: center;
  line-height: 1;
  background-color: white;
  padding: 2mm;
}

.report-print-detail-score .score-table > .box-item > .box-item-list {
  list-style-type: disc;
  padding: 1mm 2mm 0 5mm;
  font-weight: 300;
  font-size: var(--font-report-small);
  line-height: 1.4;
}

/* .report-print-detail-footer */
.report-print-detail-footer {
  margin-top: auto;
}

.report-print-detail-footer > .footer-item {
  width: 42%;
}

.report-print-detail-footer .footer-item-title {
  font-size: var(--font-report-normal);
  font-weight: 600;
  margin-bottom: 1mm;
}

.report-print-detail-footer .footer-item-content {
  font-size: var(--font-report-small);
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 1mm;
}

.report-print-detail-footer .footer-item-content > ul {
  list-style-type: '\2714';
  padding-left: 3mm;
}

.report-print-detail-footer .footer-item-content > ul > li {
  margin-bottom: 1mm;
  line-height: 1.3;
}

.report-print-detail-footer .footer-item-content > ul > li > span {
  position: relative;
  left: 1.5mm;
}

.report-print-qr-code {
  width: 19mm;
}

.report-print-qr-code-desc {
  font-size: var(--font-report-small);
  font-weight: 600;
  text-align: center;
  margin-top: 1.5mm;
  line-height: 1.15;
  @apply text-primary;
}
