.recording-button {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.recording-button.default {
  background-image: url('/public/assets/images/recording-button/recording-button-default.svg');
}

.recording-button.active {
  background-image: url('/public/assets/images/recording-button/recording-button-active.svg');
}

.recording-button.done {
  background-image: url('/public/assets/images/recording-button/recording-button-done.svg');
}

.recording-button.warning {
  background-image: url('/public/assets/images/recording-button/recording-button-warning.svg');
}

.recording-button.disabled {
  cursor: not-allowed;
  background-image: url('/public/assets/images/recording-button/recording-button-disabled.svg') !important;
}

.recording-button:hover {
  opacity: 80%;
}

.recording-button.disabled:hover {
  opacity: 100%;
}

.recording-button.no-hover:hover {
  opacity: 100%;
}
