@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
  @apply m-0 antialiased text-grey-900;
}

/* font-family for cookieWow banner */
.cwc-cookie-banner-ui-sdk,
.cwc-cookie-banner-ui-sdk button,
.cwc-cookie-banner-ui-sdk span,
.cwc-cookie-banner-ui-sdk a {
  /* to keep it in-sync with tailwind.config.js (`theme.fontFamily.sans`) */
  @apply !font-sans;
}

@layer base {
  hr {
    @apply border-divider;
  }
}

@layer utilities {
  .text-gradient-gd-1 {
    @apply text-transparent bg-clip-text bg-gradient-to-t from-[#275DF8]/80 from-0% to-[#0E34A0] to-100%;
  }
}
