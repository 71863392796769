.gradient-text-primary {
  @apply text-transparent bg-clip-text bg-gradient-to-t from-[#275DF8]/80 from-60% to-[#0E34A0] to-100%;
}
.report-summary-box-container {
  background-image: url('/public/assets/images/report-app/report-detail-report-summary-section-background.png'),
    linear-gradient(to bottom, #173382, #0f2257);
  background-repeat: no-repeat;
  background-size: 100% auto, 100% 100%;
  background-position: center bottom, top left;
  @apply flex flex-col items-center w-full rounded-3xl py-[60px] text-white;
}

.report-summary-share-icon {
  @apply flex justify-center items-center w-7 aspect-square text-primary bg-white border border-primary-100 rounded-xl;
}

/* CEFR Score Section */

.cefr-score-gradient-title {
  @apply gradient-text-primary;
}

.cefr-score-chart-container {
  @apply hidden sm:grid grid-cols-8 gap-2 items-end w-full pt-6;
  grid-template-rows: 1fr auto auto;
}

.cefr-score-chart-bar {
  @apply p-1 sm:p-2 flex flex-col justify-between items-center rounded-xl border-4;
}

.cefr-score-chart-bar-background-stripe {
  --stripe-width: 8px;
  background-image: repeating-linear-gradient(
    135deg,
    var(--tw-gradient-from),
    var(--tw-gradient-from) var(--stripe-width),
    var(--tw-gradient-to) var(--stripe-width),
    var(--tw-gradient-to) calc(var(--stripe-width) * 2)
  );
}

.cefr-score-chart-mobile-bar-background-stripe {
  --stripe-width: 6px;
  background-image: repeating-linear-gradient(
    135deg,
    var(--tw-gradient-from),
    var(--tw-gradient-from) var(--stripe-width),
    var(--tw-gradient-to) var(--stripe-width),
    var(--tw-gradient-to) calc(var(--stripe-width) * 3)
  );
}

.consultation-section-container {
  background-image: url('/public/assets/images/report-app/report-detail-consultation-section-background.png'),
    linear-gradient(to bottom, #0e34a0, #275df8);
  background-repeat: no-repeat;
  background-size: 100% auto, 100% 100%;
  background-position: center bottom, top left;
  @apply py-8 px-5 sm:py-11 sm:px-8 rounded-3xl;
}

/* Compare with Other Standards Section */

.other-standards-gradient-title {
  @apply gradient-text-primary;
}
