.table {
  @apply border border-collapse border-black;
}

.table td,
th {
  @apply border border-black p-2;
}

.web-container {
  @apply print:hidden;
}

.print-container {
  @apply hidden print:block;
}
