/* Use class starting with number to match the variant in tailwind config. */
.\32xs {
  @apply text-2xs;
}
.xs {
  @apply text-xs;
}
.sm {
  @apply text-sm;
}
.md {
  @apply text-base;
}
.lg {
  @apply text-lg;
}
.xl {
  @apply text-xl;
}
.\32xl {
  @apply text-2xl;
}
.\33xl {
  @apply text-3xl;
}
.\34xl {
  @apply text-4xl;
}
.\35xl {
  @apply text-5xl;
}
.\36xl {
  @apply text-6xl;
}
.\37xl {
  @apply text-7xl;
}
