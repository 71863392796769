.circular-progress-container {
  @apply w-full aspect-[2] mx-auto relative isolate;
}

.circular-progress-container::before {
  content: '';
  width: var(--knob-size);
  height: var(--knob-size);
  transform: translate(var(--x-offset), var(--y-offset));
  @apply absolute z-10 border-[5px] border-white bg-primary-500 rounded-full;
}
