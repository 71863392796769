.recording-panel-container {
  height: 108px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'left center right';
  align-items: center;
  background: linear-gradient(to top, rgba(255, 255, 255), rgba(255, 255, 255, 0.9));
  position: relative;
}

.recording-timer-container,
.preparation-timer-container {
  grid-area: left;
}
.recording-button-container {
  grid-area: center;
}
.voice-graph-container {
  grid-area: center;
}
.finish-button-container {
  grid-area: right;
  justify-self: right;
}

.recording-button {
  width: 58px;
  height: 58px;
  cursor: pointer;
  background-size: contain;
  background-image: url('/public/assets/images/recording-button-v2/recording-button-primary.svg');
}

.recording-button:hover {
  background-image: url('/public/assets/images/recording-button-v2/recording-button-primary-hover.svg');
}

.timer-bar-icon {
  border-radius: 100%;
  background: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: calc(theme('screens.sm') - 1px)) {
  .recording-panel-container {
    height: 92px;
  }

  .recording-state.recording-panel-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
  }
  :not(.recording-state).recording-panel-container {
    grid-template-columns: 1fr;
    grid-template-areas: 'center';
  }

  .recording-timer-container,
  .preparation-timer-container {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, calc(-100% - 12px));
    background: white;
    box-shadow: 0px -1px 15px 1px rgba(0, 0, 0, 0.25);
    padding: 4px 16px;
    border-radius: 1000px;
    grid-area: none;
  }
  .recording-state .voice-graph-container {
    grid-area: left;
  }

  .recording-button {
    width: 48px;
    height: 48px;
  }
}
