:root {
  --font-report-small: 7pt;
  --font-report-normal: 8pt;
  --font-report-medium: 10pt;
  --font-report-large: 11pt;
  --font-report-heading: 14pt;
}

/* Layout */
.report-print-wrapper {
  break-after: always;
  width: 210mm;
  /* .8 length is a workaround to prevent page to be larger than one page. */
  height: 296.8mm;
  background-size: contain;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 241, 209, 0) 0%, #ffdf99 100%);
  white-space: pre-line;
}

.report-print-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.report-print-inner-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 18mm 12mm 6mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.school-logo {
  position: absolute;
  width: auto;
  height: 19mm;
  top: 6mm;
  left: 9mm;
}

.edsy-logo {
  position: absolute;
  width: 22mm;
  top: 12mm;
  right: 9mm;
}

.report-print-header {
  font-size: 16pt;
  white-space: pre-line;
  line-height: 1.3;
  font-weight: 900;
  text-align: center;
  margin-bottom: 8mm;
}

.report-print-overall-info {
  display: flex;
  align-items: center;
  gap: 8mm;
  padding: 6mm;
  width: 100%;
  margin-bottom: 9mm;
  border-radius: 4mm;
  @apply bg-yellow-50;
}

.report-print-student-info {
  width: 64mm;
  font-size: var(--font-report-large);
  line-height: 1.35;
  font-weight: 400;
  align-items: center;
  gap: 4mm;
}

.report-print-overall-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1mm;
  width: 100%;
  padding: 0 10mm;
  margin-bottom: 6mm;
}

.checkmark-list {
  list-style-type: '\2714';
  padding-left: 4mm;
}

.checkmark-list-item {
  font-size: var(--font-report-large);
  padding-left: 1mm;
}

.report-print-qr-code-container {
  width: 25mm;
  height: 25mm;
  background: white;
  border-radius: 2mm;
  border: 0.5mm solid black;
  padding: 1.5mm;
}

.report-print-qr-code {
  width: 100%;
}

.report-print-qr-code-desc {
  font-size: var(--font-report-medium);
  line-height: 1;
  margin-bottom: 1mm;
  width: 100%;
  font-weight: 600;
}

.score-box-container {
  @apply grid grid-cols-3 grid-rows-[auto_auto] grid-flow-col gap-x-[2mm] gap-y-[0.5mm];
}

.score-box {
  display: flex;
  flex-direction: column;
  border-radius: 4mm;
  overflow: hidden;
}

.score-box-header {
  padding: 3mm 1.5mm;
}

.score-box-header-title {
  font-size: var(--font-report-large);
  font-weight: 600;
}

.score-box-header-subtitle {
  font-size: var(--font-report-medium);
}

.score-box-header-score-desc {
  font-size: var(--font-report-medium);
}

.score-box-description {
  @apply flex flex-col items-center gap-y-[2mm] px-[2mm] py-[3mm] border-yellow-400 border-[0.5mm];
  background-color: #fffbf3;
  border-radius: 0 0 4mm 4mm;
  font-size: 8pt;
  line-height: 1.15;
  flex-grow: 1;
}

.score-box-recommendation {
  display: flex;
  align-items: flex-start;
  color: #777;
  font-size: 8pt;
  column-gap: 1.5mm;
  border-radius: 2mm;
  padding: 1.5mm;
  background-color: white;
}

.cefr-score-box {
  min-width: 48mm;
  padding: 6mm 4mm;
  border-radius: 5mm;
  background: linear-gradient(180deg, #173382 0%, #0f2257 100%);
}

.cefr-score-box-cefr-score {
  font-size: 15.5pt;
  padding: 1mm 3mm;
  margin-bottom: 4mm;
}

.cefr-score-box-title {
  font-size: 12pt;
  font-weight: 600;
}

.cefr-score-box-score {
  font-size: 28pt;
  font-weight: 900;
  margin-bottom: 1mm;
}

.cefr-score-box-subtitle {
  font-size: 12pt;
}

.cefr-chart-section {
  border-radius: 11mm;
  display: flex;
  gap: 7mm;
  padding: 5mm 6mm 8mm;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.cefr-chart-section-chart-container {
  flex-grow: 2.5;
  flex-basis: 0;
  position: relative;
}

.cefr-chart-section-your-score-label-container {
  @apply absolute top-0 left-0 flex items-center gap-x-1 font-semibold;
  font-size: var(--font-report-large);
}

.cefr-chart-section-desecription-container {
  flex-grow: 1;
  flex-basis: 0;
  padding-top: 8mm;
  @apply flex flex-col gap-[2mm];
}

.cefr-chart-section-description-level {
  font-size: 11pt;
  font-weight: 600;
  padding: 1mm 3mm;
  border: 1.2pt solid;
  @apply text-primary border-grey-300 rounded-full text-center w-fit capitalize;
}

.cefr-chart-section-header {
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 11pt;
  font-weight: 600;
  padding: 4mm 3mm;
  border-radius: 4mm 4mm 0 0;
  margin-right: 7mm;
  margin-left: 12mm;
  width: 56mm;
  background-color: #ffcb5a;
  @apply text-center;
}

.cefr-chart-section-cefr-description {
  flex-grow: 1;
  border-left: 1pt solid;
  padding: 1mm 3mm;
  font-size: 9pt;
  line-height: 1.35;
  @apply border-grey-300 text-grey-600;
}

.cefr-chart-section-description-cefr-desc {
  font-size: 9pt;
  padding-bottom: 4mm;
  padding-left: 1mm;
}

.cefr-chart-section-description-motivation {
  font-size: 9pt;
  padding: 3mm;
  border-radius: 4mm;
  @apply bg-green-100 text-green-800;
}

.footer-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}
