.category-tabs-container {
  @apply p-[2px] sm:p-2 flex sm:gap-x-4 bg-grey-50 rounded-xl sm:rounded-3xl;
}

.category-tab {
  @apply flex-1 py-[8px] px-[10px] sm:py-3 sm:px-4 rounded-2xl text-center transition-colors basis-0;
  /* hover */
  @apply hover:text-gray-700;
}

.overall-score-banner {
  background-image: url('/public/assets/images/report-app/report-detail-category-summary-decoration-bg.png'),
    linear-gradient(to top, #0e34a0, rgba(39 93 248 / 0.8));
  background-repeat: no-repeat;
  background-size: auto auto, 100% 100%;
  background-position: right bottom, top left;
  @apply w-full py-1 px-9 flex items-center gap-x-3 rounded-3xl;
}
