.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.wrapper {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 md:p-8 outline-none max-w-full z-[1001];
}

.sm {
  @apply w-[320px];
}

.md {
  @apply w-[600px];
}

.lg {
  @apply w-[768px];
}

.xl {
  @apply w-[1024px]
}

.modal-card {
  @apply bg-white rounded-2xl shadow-md p-8 max-h-[90vh] overflow-auto;
}

.no-padding {
  @apply p-0;
}
