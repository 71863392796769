.rounded-icon {
  border-radius: 30px;
  /* border: 2px solid black; */
  background-color: white;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-right: 6px;
  width: fit-content;
  
  @apply text-primary
}