.base {
  @apply border border-grey-400 flex font-semibold leading-[23.2px];
}

.base-spacing {
  @apply px-5 py-3;
}

.input {
  @apply rounded-full disabled:bg-white;
}

/* Hide arrow from input type number*/
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}

.textarea {
  @apply rounded-3xl;
}

.dropdown {
  @apply rounded-full pr-5;
}

.dropdown-select {
  @apply w-full px-5 py-3 rounded-full bg-transparent cursor-pointer !outline-none;
}

.disabled {
  @apply !cursor-not-allowed !text-grey-500;
}

.readonly {
  @apply disabled:cursor-not-allowed;
}
