:root {
  --font-report-small: 7pt;
  --font-report-normal: 8pt;
  --font-report-medium: 10pt;
  --font-report-large: 11pt;
  --font-report-heading: 14pt;
}

/* Layout */
.report-print-wrapper {
  break-after: always;
  width: 210mm;
  /* .8 length is a workaround to prevent page to be larger than one page. */
  height: 296.8mm;
  background-size: contain;
  position: relative;
}

.report-print-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.report-print-inner-background {
  position: absolute;
  top: 45mm;
  left: 7mm;
  right: 6mm;
  bottom: 100mm;
  padding: 12mm 8mm 6mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-print-header {
  font-size: 16pt;
  white-space: nowrap;
  line-height: 1.3;
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 16mm;
  transform: translate(-50%, -50%);
  text-align: center;
}

.report-print-title {
  font-size: var(--font-report-heading);
  line-height: 1.3;
  font-weight: 600;
  color: white;
  width: 60%;
  display: flex;
  justify-content: center;
  border-radius: 9999px;
  padding: 3mm;
  position: absolute;
  left: 50%;
  top: 38mm;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  @apply bg-primary;
}

.report-print-overall-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 12mm 0 8mm;
  width: 100%;
  margin-bottom: 5mm;
}

.report-print-student-info {
  font-size: var(--font-report-medium);
  line-height: 1.35;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4mm;
}

.report-print-mascot-container {
  width: 28.5mm;
  height: 28.5mm;
  background-color: #eef1fc;
  border: 1px solid #d8e0f8;
  border-radius: 8mm;
  padding: 2mm;
}

.report-print-mascot {
  width: 100%;
  height: 100%;
}

/* .report-print-overall-result */
.report-print-overall-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1mm;
  width: 100%;
  padding: 0 10mm;
  margin-bottom: 6mm;
}

.report-print-overall-result .overall-result-title {
  font-size: var(--font-report-large);
  font-weight: 600;
  display: flex;
  gap: 1mm;
}

.report-print-overall-result .overall-result-container {
  display: flex;
  justify-content: space-between;
  gap: 4mm;
  background-color: white;
  width: 100%;
  margin: 0 2mm;
  border: 0.5mm solid #1f45b1;
  padding: 1.5mm 7mm 1.5mm 3mm;
}

.report-print-overall-result .overall-result-item {
  display: flex;
  align-items: center;
  gap: 3mm;
}

.report-print-overall-result .standard-logo {
  object-fit: contain;
}

.report-print-overall-result .cefr-logo {
  width: 24mm;
}

.report-print-overall-result .cambridge-logo {
  width: 42mm;
}

.report-print-overall-result .label {
  width: 24mm;
  height: 8mm;
  color: white;
  background-color: #1f45b1;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-report-medium);
  line-height: 1;
  @apply font-semibold;
}

/* .report-print-detail-footer */
.report-print-detail-footer {
  margin-top: auto;
  display: flex;
  justify-content: space-around;
}

.report-print-detail-footer > .footer-item {
  max-width: 50%;
}

.report-print-detail-footer .footer-item-title {
  font-size: 9pt;
  font-weight: 700;
  margin-bottom: 1mm;
}

.report-print-detail-footer .footer-item-content {
  font-size: var(--font-report-normal);
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 1mm;
}

.report-print-detail-footer .footer-item-content > ul {
  list-style-type: '\2714';
  padding-left: 3mm;
}

.report-print-detail-footer .footer-item-content > ul > li {
  margin-bottom: 1mm;
  line-height: 1.2;
}

.report-print-detail-footer .footer-item-content > ul > li > span {
  position: relative;
  left: 1.5mm;
}

.report-print-qr-code-container {
  width: 28.5mm;
}

.report-print-qr-code {
  width: 100%;
}

.report-print-qr-code-desc {
  font-size: var(--font-report-small);
  line-height: 1;
  margin-bottom: 1mm;
  width: 100%;
  font-weight: 600;
  text-align: center;
  @apply text-primary;
}
