.report-summary-box-container {
  background-image: url('/public/assets/images/report-app/report-detail-report-summary-section-background.png'),
    linear-gradient(to bottom, #173382, #0f2257);
  background-repeat: no-repeat;
  background-size: 100% auto, 100% 100%;
  background-position: center bottom, top left;
  @apply flex flex-col items-center w-full rounded-s-3xl text-white;
}

.report-summary-share-icon {
  @apply flex justify-center items-center w-7 aspect-square text-primary bg-white border border-primary-100 rounded-xl;
}

/* CEFR Score Section */

.cefr-score-gradient-title {
  @apply text-transparent bg-clip-text bg-gradient-to-t from-[#275DF8]/80 from-60% to-[#0E34A0] to-100%;
}

.cefr-score-chart-container {
  @apply grid grid-cols-8 gap-2 items-end w-full;
  grid-template-rows: 1fr auto auto;
}

.cefr-score-chart-bar {
  @apply flex flex-col justify-between items-center rounded-lg border-[1mm];
  padding: 2mm 1mm;
}

.cefr-score-chart-bar-background-stripe {
  --stripe-width: 8px;
  background-image: repeating-linear-gradient(
    135deg,
    var(--tw-gradient-from),
    var(--tw-gradient-from) var(--stripe-width),
    var(--tw-gradient-to) var(--stripe-width),
    var(--tw-gradient-to) calc(var(--stripe-width) * 2)
  );
}

.chart-level-bar-level {
  font-size: 7.5pt;
  font-weight: 900;
}

.chart-level-bar-label-title {
  font-size: 7pt;
}

.chart-level-bar-label-range {
  font-size: 7pt;
}

.scale-description {
  font-size: 6pt;
  font-weight: 600;
}

.your-level-text {
  font-size: 9pt;
  padding: 0pt 1mm;
}
