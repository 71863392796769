.speed-graph-cell {
  @apply w-[60px] h-[56px] max-w-[60px];
}

/* Set max-width here to make sure the label will not push the boundary of the container. */
.speed-graph-cell-container {
  @apply max-w-[60px];
}

.speed-graph-cell-container:first-child .speed-graph-cell {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.speed-graph-cell-container:last-child .speed-graph-cell {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}
