.button {
  display: block;
  cursor: pointer;
  @apply rounded-full disabled:cursor-not-allowed border text-center;
}

.primary {
  @apply bg-primary border-primary text-white;
  /* hover */
  @apply hover:bg-primary-700 hover:border-primary-700;
  /* disabled */
  @apply disabled:bg-grey-400 disabled:border-grey-400 disabled:text-white;
}

.secondary {
  @apply bg-primary-100 border-primary-100 text-primary;
  /* hover */
  @apply hover:bg-primary-300 hover:border-primary-300;
  /* disabled */
  @apply disabled:bg-white disabled:border-white disabled:text-grey-400;
}

.outlined {
  @apply bg-transparent text-primary border-primary;
  /* hover */
  @apply hover:bg-primary-50 hover:text-primary-700;
  /* disabled */
  @apply disabled:bg-grey-400 disabled:text-white disabled:border-grey-400;
}

.outlined-gray {
  @apply bg-transparent text-grey-500 border-grey-500;
  /* hover */
  @apply hover:bg-grey-50 hover:text-grey-700;
  /* disabled */
  @apply disabled:bg-grey-400 disabled:text-white disabled:border-grey-400;
}

.flat {
  @apply bg-transparent text-primary-500 border-none;
  /* hover */
  @apply hover:text-primary-700;
  /* disabled */
  @apply disabled:text-grey-400;
}

.flatgrey {
  @apply bg-transparent text-grey-400 border-none;
  /* hover */
  @apply hover:text-grey-600;
  /* disabled */
  @apply disabled:text-grey-200;
}

.on-dark {
  @apply bg-white text-grey-600 border-grey-200;
  /* hover */
  @apply hover:text-primary hover:border-primary-100;
  /* disabled */
  @apply disabled:bg-grey-200 disabled:text-grey-400 disabled:border-transparent;
}

.xlarge {
  @apply px-8 py-3 text-lg font-semibold;
}

.large {
  @apply px-8 py-3 text-base font-semibold;
}

.normal {
  @apply px-5 py-2 text-sm font-medium;
}

.small {
  @apply px-3 py-2 text-xs font-medium;
}

.flat.large,
.flat.normal,
.flat-white.large,
.flat-white.normal {
  @apply px-0 py-0;
}
